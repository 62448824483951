import { Controller } from "stimulus"

export default class extends Controller {
    set(e) {
        var sectionTag = e.currentTarget.dataset.sectionTag
        $(".small-filter-button").removeClass("small-filter-button-active")
        $(".small-filter-button").addClass("small-filter-button-inactive")
        $(e.currentTarget).removeClass("small-filter-button-inactive")
        $(e.currentTarget).addClass("small-filter-button-active")

        let theOtherPopupID = e.currentTarget.dataset.type == "viplist" ? "highscore" : "viplist"
        $("#"+e.currentTarget.dataset.type).val(e.currentTarget.dataset.value);
        $("#"+theOtherPopupID).val("");
    }
}
