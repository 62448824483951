import tippy from "tippy.js"

document.addEventListener("turbolinks:load", () => {
  tippy(document.querySelectorAll("[data-tippy-content]"))
})

function reloadTippies() {
  let tips = document.querySelectorAll("[data-tippy-content]");
  tips.forEach((e) => {
      if (e._tippy) e._tippy.destroy();
  });
  tippy(document.querySelectorAll("[data-tippy-content]"));
};

export default reloadTippies;