// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import ContentLoader from "stimulus-content-loader"
import ScrollReveal from "stimulus-scroll-reveal"
import Lightbox from "stimulus-lightbox"
import Clipboard from "stimulus-clipboard"
import Sortable from "stimulus-sortable"
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
import controller from '../controllers/application_controller'

const application = Application.start()

application.register("content-loader", ContentLoader)
application.register("lightbox", Lightbox)
application.register("scroll-reveal", ScrollReveal)
application.register("clipboard", Clipboard)
application.register("sortable", Sortable)

const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
StimulusReflex.initialize(application, { controller, isolate: true })
StimulusReflex.debug = process.env.RAILS_ENV === 'development'
application.consumer = consumer

import debounced from 'debounced'
debounced.initialize()

jQuery.expr[':'].contains = function(a, i, m) {
    // this make jquery.contains case insensitive
    return jQuery(a).text().toUpperCase()
        .indexOf(m[3].toUpperCase()) >= 0;
};