import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['source', 'output', 'outputasvalue', 'hideifblank']
      
    initialize () {
        this.process = this.process.bind(this)
    }

    connect () {
        this.sourceTarget.addEventListener('input', this.process)
    }

    disconnect () {
        this.sourceTarget.removeEventListener('input', this.process)
    }
  
    process(event) {
        var newString = this.sourceTarget.value

        if (newString) {
            if (this.hasHideifblankTarget) {
                this.hideifblankTarget.classList.add("inline-block");
                this.hideifblankTarget.classList.remove("hidden");
            }
        } else {
            if (this.hasHideifblankTarget) {
                this.hideifblankTarget.classList.add("hidden");
                this.hideifblankTarget.classList.remove("inline-block");
            }
        }

        if (this.hasOutputTarget) {
            this.outputTarget.innerText = newString
        }

        if (this.hasOutputasvalueTarget) {
            this.outputasvalueTarget.value = newString
        }
    }
  }