import { Controller } from "stimulus"

export default class extends Controller {
    show(e) {
        var sectionTag = e.currentTarget.dataset.sectionTag
        $(".section-link").removeClass("section-link-selected")
        $(".section-display").addClass("hidden")

        $("#link_"+sectionTag).addClass("section-link-selected")
        $("#section_"+sectionTag).removeClass("hidden")

        setTimeout(window.reloadTippies(),2000);
    }

    preventDefault(e) {
        e.preventDefault();
        return false;
    }
}
