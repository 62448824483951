import { Controller } from "stimulus"

export default class extends Controller {
    checkFields(e) {
        let preventAction = false;
        
        $(".rsvp-city-selector").each(function() {
            let cityType = $(this).data("city-type");
            let hasIssue = false;

            if ($(this).val()) {
                $(".rsvp-form-field."+cityType).each(function() {
                    if (!$(this).val()) {
                        preventAction = true;
                        hasIssue = true;
                    }
                });
            }

            if (hasIssue) {
                $("#link_"+ cityType + "_warning").removeClass("hidden");
            } else {
                $("#link_"+ cityType + "_warning").addClass("hidden");
            }
        });

        if (preventAction) {
            $("#general_error").removeClass("hidden");
            e.preventDefault();
            e.stopImmediatePropagation(); 
            return false
        } else {
            $("#general_error").addClass("hidden");
        }
    }
}
