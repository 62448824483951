import { Controller } from "stimulus"

export default class extends Controller {
  toggle(e) {
    var value = e.currentTarget.checked;

    var className = "selectall-child"

    if (e.currentTarget.dataset.childClass) {
      className = e.currentTarget.dataset.childClass
    }

    $("."+className).prop("checked",value);
    if(value != true) {
        // $(".selectall-child").removeClass("hidden",400);
    } else {
        // $(".selectall-child").addClass("hidden",400);
    }
  }
}
