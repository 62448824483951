import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['field', 'collisions']
      
    initialize () {
        this.process = this.process.bind(this)
        this.checkCollisions = this.checkCollisions.bind(this)
    }

    connect () {
        for(var i = 0; i < this.fieldTargets.length; i++) {
            this.fieldTargets[i].addEventListener('change', this.process)
        }
    }

    disconnect () {
        for(let i = 0; i<this.fieldTargets.length; i++){
            this.fieldTargets[i].removeEventListener("change", this.process) 
        }
    }
  
    process(event) {
        for(let i = 0; i<this.fieldTargets.length; i++){
            if (this.fieldTargets[i] != event.currentTarget && this.checkCollisions(this.fieldTargets[i].value, event.currentTarget.value)) {
                this.fieldTargets[i].value = ""
            }
        }
    }

    checkCollisions(a, b) {
        if (a == b) {
            return true;
        }

        var chunks = this.collisionsTarget.value.split("/")

        for(let i = 0; i<chunks.length; i++) {
            var pieces = chunks[i].split(",")
            if (pieces.includes(a) && pieces.includes(b)) {
                return true;
            }
        }

        return false;
    }
  }