// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>


import { Controller } from "stimulus"
import Rails from "@rails/ujs";

var subclass
var ctype

export default class extends Controller {
  static targets = [ "search" , "output" ]

  connect() {
    subclass = this.data.get("subclass")
    ctype = this.data.get("type")
  }

  filter() {
    var search_text = this.searchTarget.value
    
    $("."+subclass).removeClass("hidden");
    $("."+subclass).not($('.'+subclass+':contains("'+search_text+'")')).not($('.'+subclass+':has(input:checked)')).addClass("hidden");

    $("."+ctype+"_container").removeClass("hidden");
    $('.'+ctype+'_container').not($('.'+ctype+'_container:contains("'+search_text+'")')).not($('.'+ctype+'_container:has(input:checked)')).addClass("hidden");
  }

  expand(e) {
    var id = e.currentTarget.dataset.id

    Rails.ajax({
      type: "get",
      url: this.data.get("expandUrl").replace(":id",id),
      dataType: "script",
      cache: false,
      error: function (XMLHttpRequest, errorTextStatus, error) {
        alert("Failed to submit : " + errorTextStatus + " ;" + error);
      },
      success: function (data) {
          // eval(data);
          $(`#${ctype}_${id}`).removeClass("hidden");
          $(`#${ctype}_${id} .${subclass}`).removeClass("hidden");
      }
    })
  }

  preventReturns(e) {
    if(e.keyCode == 13) {
      e.preventDefault();
      return false;
    }
  }
}
