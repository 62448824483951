import ApplicationController from './application_controller'

export default class extends ApplicationController {

    hideModal (e) {
        document.getElementById("stimulus_modal").innerHTML = "";
    }

    hideConfirmationModal (e) {
        document.getElementById("stimulus_confirmation_modal").innerHTML = "";
    }

    performAfterAction(e) {   
        let hx = $("#stimulus_modal_after_action").get(0);
        this.stimulate(hx.dataset.afterAction, hx);
    }
}