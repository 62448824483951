import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static targets = ['menubutton', 'menubuttontext', 'menubuttonsubtext', 'hiddenformitem', 'menu', 'row']
      
    initialize () {
        this.selectRow = this.selectRow.bind(this)
        this.openMenu = this.openMenu.bind(this)
    }

    beforeReflex (element, reflex, noop, reflexId) {
        document.body.classList.add('wait')
        super.beforeReflex(element, reflex, noop, reflexId);
    }
    
    afterReflex (element, reflex, noop, reflexId) {
        document.body.classList.remove('wait')
        this.reload_tippies();
        super.afterReflex(element, reflex, noop, reflexId);
    }

    connect () {
        super.connect()

        this.menubuttonTarget.addEventListener('click', this.openMenu)
        for(var i = 0; i < this.rowTargets.length; i++) {
            this.rowTargets[i].addEventListener('click', this.selectRow)
        }
    }

    disconnect () {
        this.menubuttonTarget.removeEventListener('click', this.openMenu)
        for(var i = 0; i < this.rowTargets.length; i++) {
            this.rowTargets[i].removeEventListener('click', this.selectRow)
        }
    }
  
    selectRow(event) {
        let newIndex = event.currentTarget.dataset.index

        for(var i = 0; i < this.rowTargets.length; i++) {
            let checkbox = this.rowTargets[i].querySelector(".row-checkmark");
            let textspan = this.rowTargets[i].querySelector(".row-text");
            let subtextspan = this.rowTargets[i].querySelector(".row-subtext");

            if (newIndex == i) {
                checkbox.classList.remove("hidden");
                textspan.classList.remove("font-normal");
                textspan.classList.add("font-semibold");

                this.menubuttontextTarget.innerText = textspan.innerText;
                this.menubuttonsubtextTarget.innerText = subtextspan.innerText;
                this.hiddenformitemTarget.value = this.rowTargets[i].dataset.rowValue;
            } else {
                checkbox.classList.add("hidden");
                textspan.classList.remove("font-semibold");
                textspan.classList.add("font-normal");
            }
        }

        this.closeMenu();
        this.stimulate(this.hiddenformitemTarget.dataset.changeAction, this.hiddenformitemTarget);
    }

    openMenu(event) {
        this.menuTarget.classList.remove("hidden")

        this.menubuttonTarget.classList.add("ring-1");
        this.menubuttonTarget.classList.add("border-indigo-500");
        this.menubuttonTarget.classList.remove("border-gray-300");
    }

    closeMenu(event) {
        this.menuTarget.classList.add("hidden")

        this.menubuttonTarget.classList.remove("ring-1");
        this.menubuttonTarget.classList.remove("border-indigo-500");
        this.menubuttonTarget.classList.add("border-gray-300");
    }
  }