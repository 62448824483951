// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("alpine-turbo-drive-adapter")
require("alpinejs")
require("jquery")
require("trix")
require("@rails/actiontext")

// Tailwind CSS
import "stylesheets/application"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import LocalTime from "local-time"
LocalTime.start()


import "controllers"
import "src/direct_uploads"
import "src/tooltips"
import reloadTippies from 'src/tooltips'
window.reloadTippies = reloadTippies

import "src/social_share"

import "lightgallery.js/dist/css/lightgallery.min.css"

import compliments_subscription from 'channels/compliments_channel'
window.compliments_subscription = compliments_subscription

import Swiper from 'swiper/swiper-bundle.js'
import 'swiper/swiper-bundle.css'
window.Swiper = Swiper

import Chart from 'chart.js/dist/chart.js'
window.Chart = Chart

import ChartDataLabels from 'chartjs-plugin-datalabels/dist/chartjs-plugin-datalabels.js'
window.ChartDataLabels = ChartDataLabels

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
window.tippy = tippy;


import SignaturePad from "signature_pad/dist/signature_pad.js"
window.SignaturePad = SignaturePad