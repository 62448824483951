import ApplicationController from './application_controller'

export default class extends ApplicationController {

    beforeReflex (element, reflex, noop, reflexId) {
        document.body.classList.add('wait')
        super.beforeReflex(element, reflex, noop, reflexId);
    }
    
    afterReflex (element, reflex, noop, reflexId) {
        document.body.classList.remove('wait')
        this.reload_tippies();
        super.afterReflex(element, reflex, noop, reflexId);
    }

    showOrHideFrequencyOther(e) {
        const field = document.getElementById('user_info_desired_travel_frequency_other')

        if (e.currentTarget.value == "other") {
            field.classList.remove("hidden");
            field.classList.add("block");
            field.focus();
            field.select();
        } else {
            field.classList.add("hidden");
            field.classList.remove("block");
        }
    }

    hideCalendarHovers(e) {
        $(".staff-schedule-hover").hide();
    }

    showCalendarHover(e) {
        var div = document.getElementById(e.currentTarget.dataset.id);
        var rect = e.target.getBoundingClientRect();
        
        var top = rect.bottom + "px";
        var left;
        
        if (e.currentTarget.dataset.side == "left") {
            left = rect.left - 220 + "px";
        } else if (e.currentTarget.dataset.side == "middle") {
            left = rect.left - 110 + "px";
        } else {
            left = rect.left - 5 + "px";
        }
        
        div.style.left = left;
        div.style.top = top;

        this.hideCalendarHovers(e);
        $("#"+div.id).show(350);
    }

    handleDragStart(e) {
        e.currentTarget.classList.add("tiny-pill-green")
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('employee_id', e.currentTarget.dataset.employeeId);

        this.hideCalendarHovers();

        var availableSkills = e.currentTarget.dataset.skills.split(",,,")
        for (var i = 0; i < availableSkills.length; i++) {
            e.dataTransfer.setData(availableSkills[i], "");
        }

        var possibleRows = document.querySelectorAll('.city-job-row');
        for (var i = 0, jobRow; jobRow = possibleRows[i]; i++) {
            this.removeAllColorsForRow(jobRow);
            jobRow.classList.add(this.determineColor(jobRow.dataset.skill, availableSkills, jobRow.dataset.filled, false));
        }
    }

    handleDragEnd(e) {
        e.currentTarget.classList.remove("tiny-pill-green");
        this.removeAllColorsForAllRows();
    }

    handleDragEnter(e) {
        if (e.dataTransfer.types.indexOf("employee_id") < 0) {
            return;
        }

        this.removeAllColorsForRow(e.currentTarget);
        e.currentTarget.classList.add(this.determineColor(e.currentTarget.dataset.skill, e.dataTransfer.types, e.currentTarget.dataset.filled, true));
    }

    handleDragOver(e) {
        e.preventDefault();
        return false;
    }

    handleDragLeave(e) {
        this.removeAllColorsForRow(e.currentTarget);
        e.currentTarget.classList.add(this.determineColor(e.currentTarget.dataset.skill, e.dataTransfer.types, e.currentTarget.dataset.filled, false));
    }

    handleDrop(e) {
        e.stopPropagation(); // stops the browser from redirecting.
        this.removeAllColorsForAllRows();

        var skillFilter = document.getElementById("skill_filter");
        var employeeSort = $('input[name="sort_employees"]:checked').val();
        
        this.stimulate("StaffSchedule#assign_employee_to_job", e.currentTarget, e.currentTarget.dataset.stageNumber, e.currentTarget.dataset.id, e.currentTarget.dataset.cityId, e.dataTransfer.getData("employee_id"), skillFilter.value, employeeSort);
        e.preventDefault();
        return false;
    }

    determineColor(skill, skillsArray, isFilled, isHoveredOver) {
        if (skillsArray.indexOf(skill) > -1) {
            if (isFilled == "true") {
                return isHoveredOver ? "bg-yellow-200" : "bg-yellow-50";
            } else {
                return isHoveredOver ? "bg-green-200" : "bg-green-50";
            }
        } else {
            return isHoveredOver ? "bg-red-200" : "bg-red-50";
        }
    }

    removeAllColorsForAllRows() {
        var possibleRows = document.querySelectorAll('.city-job-row');
        for (var i = 0, jobRow; jobRow = possibleRows[i]; i++) {
            this.removeAllColorsForRow(jobRow);
        }
    }

    removeAllColorsForRow(element) {
        element.classList.remove("bg-yellow-200")
        element.classList.remove("bg-yellow-50")
        element.classList.remove("bg-green-200")
        element.classList.remove("bg-green-50")
        element.classList.remove("bg-red-200")
        element.classList.remove("bg-red-50")
    }
} 
