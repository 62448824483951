import ApplicationController from './application_controller'

export default class extends ApplicationController {
    beforeReloadTable (element, reflex, noop, reflexId) {
        document.body.classList.add('wait')
        
        $(".city-list").append('<span class="hidden pending-reload"></span>');
    }

    afterReloadTable (element, reflex, noop, reflexId) {
        document.body.classList.remove('wait')
        
        $(".pending-reload").parent().html("");

        if ($('.city-selector-checkbox:checked').length) {
            $("#city_clear_button").removeClass("hidden");
        } else {
            $("#city_clear_button").addClass("hidden");
        }
    }
}