import { Controller } from 'stimulus'
import StimulusReflex from 'stimulus_reflex'
import tippy from "tippy.js";

/* This is your ApplicationController.
 * All StimulusReflex controllers should inherit from this class.
 *
 * Example:
 *
 *   import ApplicationController from './application_controller'
 *
 *   export default class extends ApplicationController { ... }
 *
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends Controller {
  connect () {
    StimulusReflex.register(this)
  }

  /* Application-wide lifecycle methods
   *
   * Use these methods to handle lifecycle concerns for the entire application.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Example#demo"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  beforeReflex (element, reflex, noop, reflexId) {
    // document.body.classList.add('wait')
    if (reflex == "PhotoEditor#save_category_edit") {
      var new_id = "category_editor_field_"+element.id.split("_").pop();
      var new_val = $('#'+new_id).val();
      const { params } = element.reflexData[reflexId]
      element.reflexData[reflexId].params = { ...params, new_value: new_val }
    }
  }

  reflexSuccess (element, reflex, noop, reflexId) {
    // show success message
  }

  reflexError (element, reflex, error, reflexId) {
    // show error message
  }

  reflexHalted (element, reflex, error, reflexId) {
    // handle aborted Reflex action
  }

  afterReflex (element, reflex, noop, reflexId) {
    // document.body.classList.remove('wait')

    const focusElement = this.element.querySelector('[autofocus]')
    if (focusElement) {
        focusElement.focus()
        try {
          focusElement.setSelectionRange(0, focusElement.value.length)
        } catch (e) {
          //some elements such as select menus don't have selections ranges, so we will ignore this error
        }
    }

    if($("#on_screen_notifications_stimulus_origin").length){
      // on screen notifications created by SR will create a span matching this id
      // this way we can animate in/out using javascript here

      $('#on_screen_notifications_inner').fadeIn('slow');
      setTimeout(function() {
          $('#on_screen_notifications_inner').fadeOut('fast');
      }, 3000);
      $("#on_screen_notifications_stimulus_origin").remove();
    }
  }

  finalizeReflex (element, reflex, noop, reflexId) {
    // all operations have completed, animation etc is now safe
  }

  reload_tippies () {
      window.reloadTippies();
  }

  toggleDivVisible(e) {
    if (!e.currentTarget.checked != !e.currentTarget.dataset.invert) {
        $("#"+e.currentTarget.dataset.id).removeClass("hidden",400);
    } else {
        $("#"+e.currentTarget.dataset.id).addClass("hidden",400);
    }
  }

  makeDivVisibleAndHideSelf(e) {
    $("#"+e.currentTarget.dataset.id).removeClass("hidden",400);
    $(e.currentTarget).addClass("hidden", 400);
  }

  toggleDivVisibleSelect(e) {
      if (e.currentTarget.value) {
          $("#"+e.currentTarget.dataset.id).removeClass("hidden",400);
      } else {
          $("#"+e.currentTarget.dataset.id).addClass("hidden",400);
      }
  }

  toggleDivVisibleSelectMatchValue(e) {
    if (e.currentTarget.value == e.currentTarget.dataset.matchValue) {
        $("#"+e.currentTarget.dataset.id).removeClass("hidden",400);
    } else {
        $("#"+e.currentTarget.dataset.id).addClass("hidden",400);
    }
  }

  toggleDivVisibleSelectUnmatchValue(e) {
    if (e.currentTarget.value != e.currentTarget.dataset.unmatchValue) {
        $("#"+e.currentTarget.dataset.unmatchId).removeClass("hidden",400);
    } else {
        $("#"+e.currentTarget.dataset.unmatchId).addClass("hidden",400);
    }
  }

  remove_nearest_row(e){
    let current_row = e.currentTarget.closest(".removable-row");
    let current_table = e.currentTarget.closest(".removable-table");
    current_table.removeChild(current_row);
  }
}
