import { Controller } from "stimulus"

export default class extends Controller {

    static targets = [ "row", "giftCertificateRows", "hiddenRow" ]

    connect(){
    }

    add_gift_certificate_row(){
        let row_to_add =  this.hiddenRowTarget.cloneNode(true);

        row_to_add.classList.remove("hidden");
        row_to_add.attributes["data-gift-certificate-rows-target"].value = "row";
        row_to_add.querySelector("#_gift_certificates__studio_id").setAttribute("required", true);

        this.giftCertificateRowsTarget.appendChild(row_to_add);
    }

    remove_gift_certificate_row(e){
        let current_row = e.currentTarget.closest(".row"); //.closest() will return the first ancestor with the matching css selector or ID or tag (https://developer.mozilla.org/en-US/docs/Web/API/Element/closest)
        this.giftCertificateRowsTarget.removeChild(current_row);
    }
}
