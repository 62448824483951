//import { Controller } from "stimulus"
import ApplicationController from './application_controller'


export default class extends ApplicationController {
    static targets= ["row", "code", "conflictPrompt"];

    initialize () {
        this.process = this.process.bind(this)
    }

    connect(){
        for(let i = 0; i<this.codeTargets.length; i++){
            this.codeTargets[i].addEventListener("input", this.process) 
        }
        this.process();
    }

    disconnect(){
        for(let i = 0; i<this.codeTargets.length; i++){
            this.codeTargets[i].removeEventListener("input", this.process) 
        }
    }

    process(event) {
        let codes_to_submit = []

        // add new code or current code to array
        for(let i = 0; i<this.codeTargets.length; i++) {
            if (this.codeTargets[i].value == ""){
                codes_to_submit[i] = this.codeTargets[i].dataset.currentCode
            } else {
                codes_to_submit[i] = this.codeTargets[i].value.toUpperCase()
            }
        }

        // set all rows to unhighlighted
        for (let i = 0; i < this.rowTargets.length; i++) {
            this.rowTargets[i].classList.remove("bg-red-300");
         }

        let numberOfDuplicates = 0;
        // check for duplicates and highlight
         for (let i = 0; i < codes_to_submit.length; i++) {
            for (let j = i + 1; j < codes_to_submit.length; j++) {
                if (codes_to_submit[i] == codes_to_submit[j]) {
                    numberOfDuplicates++;
                    this.rowTargets[i].classList.add("bg-red-300")
                    this.rowTargets[j].classList.add("bg-red-300")
                }
            }
         }
        
        if (numberOfDuplicates > 0) {
            this.conflictPromptTarget.innerText = `${numberOfDuplicates} conflict(s) found`
        } else {
            this.conflictPromptTarget.innerText = "";
        }
    }
}