import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static targets = ['source', 'output', 'deductfrom', 'remaining']
      
    initialize () {
        this.process = this.process.bind(this)

        // Create our number formatter.
        this.formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
        
            // These options are needed to round to whole numbers if that's what you want.
            //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
            //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
    }

    connect () {
        super.connect()

        for(var i = 0; i < this.sourceTargets.length; i++) {
            this.sourceTargets[i].addEventListener('input', this.process)
        }
    }

    disconnect () {
        for(var i = 0; i < this.sourceTargets.length; i++) {
            this.sourceTargets[i].removeEventListener('input', this.process)
        }
    }
  
    process(event) {
        var total = 0.0

        for(var i = 0; i < this.sourceTargets.length; i++) {
            total += Number(this.sourceTargets[i].value.replace(/[^0-9.-]+/g,""));
        }

        if (this.hasDeductfromTarget && this.hasRemainingTarget) {
            var deductfrom = Number(this.deductfromTarget.value.replace(/[^0-9.-]+/g,""));
            this.remainingTarget.innerText = this.formatter.format(deductfrom - total)
        }

        this.outputTarget.innerText = this.formatter.format(total)
    }
        
  }