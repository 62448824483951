import Clipboard from "stimulus-clipboard"

export default class extends Clipboard {
  copyHidden (e) {
    // hidden dom elements can't be copied, so we unhide, copy, and rehide
    this.sourceTarget.classList.remove("hidden")
    this.copy(e);
  }

  copied () {
    super.copied();

    if (this.data.get("hidesSourceAfter") == "true") {
        this.sourceTarget.classList.add("hidden")
    }
  }
}