import ApplicationController from './application_controller'
import Rails from "@rails/ujs";

var intervalId;
var firstLoad;
var composedUrl;
var savedValues = {};
var hasSavedYet = false;
var myControllerRef;


export default class extends ApplicationController {
    connect() {
        super.connect()
        let id = this.data.get("id");
        let url = this.data.get("url");
        composedUrl = url.replace(":id",id);
        // #153 
        //firstLoad = setTimeout(this.refresh, 3000);
        //intervalId = setInterval(this.refresh, 120000);
        myControllerRef = this;
    }

    disconnect() {
        clearInterval(intervalId);
    }

    autoRefreshSuccess(element, reflex, noop, reflexId) {
        $("#compliments_"+element.dataset.id).removeClass("hidden");
    }

    refresh(e) {   
        Rails.ajax({
            type: "get",
            url: composedUrl,
            dataType: "json",
            cache: false,
            error: function (XMLHttpRequest, errorTextStatus, error) {
                //
            },
            success: function (data) {
                let rr = JSON.parse(data)
                
                for(var i = 0; i < rr.length; i++) {
                    var obj = rr[i];
                    if (savedValues[obj[0]] != obj[1]) {
                        savedValues[obj[0]] = obj[1]

                        if (hasSavedYet == true) {
                            let hx = $("#compliments_button_" + obj[0]).get(0);
                            myControllerRef.stimulate("Compliments#auto_refresh", hx);
                        }
                    }
                }

                hasSavedYet = true;
            }
        })
    }
}