import ApplicationController from './application_controller'

export default class extends ApplicationController {
    setMetaFilterSuccess (element, reflex, noop, reflexId) {
        $(".related-button").removeClass("related-button-active");
        $(".related-button").addClass("related-button-inactive");
        $("#related_"+element.dataset.index).removeClass("related-button-inactive");
        $("#related_"+element.dataset.index).addClass("related-button-active");
    }
}
