import ApplicationController from './application_controller'

export default class extends ApplicationController {
    beforeReflex (element, reflex, noop, reflexId) {
        var expanded = (this.data.get("expanded") == "true" ? true : false)
        var popup = (this.data.get("popup") == "true" ? true : false)
        var divForce = this.data.get("div-force")
        if (expanded || popup || divForce) {
            const { params } = this.element.reflexData[reflexId]
            this.element.reflexData[reflexId].params = { ...params, expanded: expanded, popup: popup, div_force: divForce }
        }
    }

    getTargetDiv (element) {
        var divForce = this.data.get("div-force")
        return "#" + (divForce ? divForce : "compliments_display_"+element.dataset.id)
    }

    beforeShow (element, reflex, noop, reflexId) {
    }

    showSuccess (element, reflex, noop, reflexId) {
        $(".compliments_display").addClass("hidden");
        $(this.getTargetDiv(element)).removeClass("hidden");
        this.reload_tippies();
    }

    hideSuccess (element, reflex, noop, reflexId) {
        //this will come from the stimulus reflex #hide action after it's successful
        $(this.getTargetDiv(element)).addClass("hidden");
    }

    hideNow (e) {
        //this will come from a data action on a regular stimulus controller
        $(this.getTargetDiv(e.currentTarget)).addClass("hidden");
    }

    addSuccess (element, reflex, noop, reflexId) {
        this.reload_tippies();
    }

    deleteSuccess (element, reflex, noop, reflexId) {
        this.reload_tippies();
    }

    autoRefreshSuccess(element, reflex, noop, reflexId) {
        $("#compliments_"+element.dataset.id).removeClass("hidden");
    }

    setPerformedSuccess (element, reflex, noop, reflexId) {
        // the value will be the opposite of the current value because this is called before the DOM updates
        switch (element.dataset.set) {
            case 'new':
                $("#compliments_"+element.dataset.id).addClass("hidden");
                break;
            case 'none':
                $("#compliments_"+element.dataset.id).removeClass("hidden");
                break;
        }
    }

    setScratchedSuccess (element, reflex, noop, reflexId) {
        // the value will be the opposite of the current value because this is called after the DOM updates the toggle button
        switch (element.dataset.set) {
            case 'false':
                $("#compliments_"+element.dataset.id).addClass("hidden");
                $("#routine_container_"+element.dataset.id).addClass("opacity-40");
                break;
            case 'true':
                $("#routine_container_"+element.dataset.id).removeClass("opacity-40");
                break;
        }
    }

    reflexError (element, reflex, error, reflexId) {
        // show error message
    }

    reflexHalted (element, reflex, error, reflexId) {
        // handle aborted Reflex action
    }

    afterReflex (element, reflex, noop, reflexId) {
        // document.body.classList.remove('wait')
    }

    finalizeReflex (element, reflex, noop, reflexId) {
        // all operations have completed, animation etc is now safe
    }

    stimulateOnReturns(e) {
        if(e.keyCode == 13) {
            this.stimulate("Compliments#add_with_text", e.target);
            e.preventDefault();
            return false;
        }
    }
}
