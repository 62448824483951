import { Controller } from "stimulus"
import Rails from "@rails/ujs";

var appendInstead = false

export default class extends Controller {
  static targets = [ "output" , "header" , "hideafter" , "sectionlinks", "closebutton" ]

  connect() {
    appendInstead = (this.data.get("append") == "true" ? true : false)
  }

  load(e) {
    var tar = this.get_correct_target(e)
    $(tar).removeClass("hidden");

    if (this.hasClosebuttonTarget) {
      $(this.closebuttonTarget).removeClass("hidden");
    }

    var hideafter = (this.hasHideafterTarget ? this.hideafterTarget : null)
    var deleteAfter = false

    if (e.currentTarget.dataset.dynamicGroupClass) {
      $("."+e.currentTarget.dataset.dynamicGroupClass).removeClass("dynamic-section-button-selected");
      $("."+e.currentTarget.dataset.dynamicGroupClass).addClass("dynamic-section-button");
      e.currentTarget.classList.remove("dynamic-section-button");
      e.currentTarget.classList.add("dynamic-section-button-selected");
    }

    if (e.currentTarget.dataset.loaderGroupClass) {
      deleteAfter = true
      hideafter = "."+e.currentTarget.dataset.loaderGroupClass
      $("."+e.currentTarget.dataset.loaderGroupClass).removeClass("hidden");
    }

    this.send_request(tar,e.currentTarget.dataset.expandUrl,"",hideafter,deleteAfter)
  }

  get_correct_target(e) {
    if (e.currentTarget.dataset.specifiedSelector) {
      return $("#"+e.currentTarget.dataset.specifiedSelector)[0];
    } else {
      return this.outputTarget;
    }
  }

  hide(e) {
    var tar = this.get_correct_target(e);
    $(tar).addClass("hidden");

    if (this.hasClosebuttonTarget) {
      $(this.closebuttonTarget).addClass("hidden");
    }
  }

  loadRoutine(e) {
    $(".content-loader-header").removeClass("border bg-purple-200");
    $(".content-loader-header").addClass("bg-gray-100 hover:bg-purple-100");
    $(".content-loader-output").addClass("hidden");
    $(".sectionlinks").removeClass("hidden");

    var tar = this.get_correct_target(e);
    var url = e.currentTarget.dataset.expandUrl;
    $(this.headerTarget).removeClass("bg-gray-100 hover:bg-purple-100");
    $(this.headerTarget).addClass("border bg-purple-200");
    $(this.sectionlinksTarget).addClass("hidden");
    $(tar).removeClass("hidden");

    this.send_request(tar,url,this.data.get("routine"))
  }


  send_request(tar,url,extra="",hideafter=null,deleteAfter=false) {
    Rails.ajax({
      type: "get",
      url: url,
      dataType: "script",
      cache: false,
      error: function (XMLHttpRequest, errorTextStatus, error) {
        alert("Failed to submit : " + errorTextStatus + " ;" + error);
      },
      success: function (data) {
        if (hideafter !== null) {
          if (deleteAfter) {
            $(hideafter).remove();
          } else {
            $(hideafter).addClass("hidden");
          }
        }

        if (appendInstead) {
          tar.innerHTML += data;

          // $([document.documentElement, document.body]).animate({
          //   scrollTop: $(tar).position().top + $(tar).offset().top + $(tar).outerHeight(true)
          // }, 400);
        } else {
          tar.innerHTML = data;

          $([document.documentElement, document.body]).animate({
            scrollTop: $(tar).offset().top-300
          }, 400);
        }

        $(".chartscript.routine-"+extra+"").each(function (index) {
          jQuery.globalEval(this.innerHTML);
        });

        $(".script-loader").each(function (index) {
          jQuery.globalEval(this.innerHTML);
        });

        setTimeout(window.reloadTippies(),1000);
      }
    })
  }


}
