import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
    tunnel(e) {    
        var id = e.currentTarget.dataset.id
        Rails.ajax({
            type: "get",
            url: e.currentTarget.dataset.tunnelUrl.replace(":id",id),
            dataType: "script",
            cache: false,
            error: function (XMLHttpRequest, errorTextStatus, error) {
                document.location="https://reg.vipdance.com";
            },
            success: function (data) {
                document.location=data
            }
        })
    }
}