import ApplicationController from './application_controller'

var intervalId;
var firstLoad;
var myControllerRef;

export default class extends ApplicationController {
    connect() {
        super.connect()
        firstLoad = setTimeout(this.refreshCount, 1500);
        // #153
        //intervalId = setInterval(this.refreshCount, 60000);
        myControllerRef = this;
    }

    refreshCountSuccess(element, reflex, noop, reflexId) {
        let showNow = ($("#notifications_count").html() == "0") ? false : true
        $("#notifications_count").toggle(showNow);
    }

    disconnect() {
        clearInterval(intervalId);
    }

    updateSettingSuccess (element, reflex, noop, reflexId) {
        $("#digest_display_"+element.dataset.id).toggle(element.checked);
    }

    refreshCount() {
        myControllerRef.stimulate("Notifications#refresh_count");
    }
}
