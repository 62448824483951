addEventListener("direct-upload:initialize", event => {
  const { target, detail } = event
  const { id, file } = detail
  target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename"></span>
      <input type="hidden" id="city_uploadables_names" name="city[uploadables_names][]" value="${file.name}">
      <input type="hidden" id="city_uploadables_paths" name="city[uploadables_paths][]" value="${file.webkitRelativePath}">
    </div>
  `)
  target.previousElementSibling.querySelector(`.direct-upload__filename`).textContent = file.name
})

addEventListener("direct-uploads:start", event => {
  const { target } = event
  if (target.dataset.instant) {
    $(target).children("#working-button").removeClass("hidden");
    $(target).children("input:submit").addClass("hidden");
    
    //if we are using instant upload then we don't want to submit the form at the end
    //we will prevent the second "click" of the button that is simulated by @rails/activestorage/src/ujs.js
    $(target).children("input:submit")[0].addEventListener("click", function(e) { 
      $(target).children("#working-button").addClass("hidden");
      $(target).children("#reload-button").removeClass("hidden");
      $(target).children("#reload-info").removeClass("hidden");
      e.preventDefault();
      e.stopImmediatePropagation(); 
    }, true);
  }
})
  
  addEventListener("direct-upload:start", event => {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.remove("direct-upload--pending")
  })
  
  addEventListener("direct-upload:progress", event => {
    const { id, progress } = event.detail
    const progressElement = document.getElementById(`direct-upload-progress-${id}`)
    progressElement.style.width = `${progress}%`
  })
  
  addEventListener("direct-upload:error", event => {
    event.preventDefault()
    const { id, error } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--error")
    element.setAttribute("title", error)
  })
  
  addEventListener("direct-upload:end", event => {
    const { id } = event.detail
    const element = document.getElementById(`direct-upload-${id}`)
    element.classList.add("direct-upload--complete")

    var parentForm = $(element).children("#city_uploadables_names")[0].form
    if (parentForm.dataset.instant == "true" && !element.classList.contains("direct-upload--error")) {

      var filename = $(element).children("#city_uploadables_names")[0].value;
      var filepath = $(element).children("#city_uploadables_paths")[0].value;
      var filekey = $(parentForm).children("input[type=hidden]").last().attr('value');
      let thisData = { instant: true, city: { uploadables_names: [filename], uploadables_paths: [filepath], uploadables: [filekey] }};

      $.ajaxSetup({
        headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }
      });
      $.ajax({
          dataType: "text",
          type: "POST",
          data: thisData,
          cache: false,
          url: parentForm.action,
          timeout: 5000,
          error: function (XMLHttpRequest, errorTextStatus, error) {
              element.classList.add("direct-upload--error")
              element.setAttribute("data-tippy-content","["+filepath+"] "+errorTextStatus);
              window.reloadTippies();
          },
          success: function (data) {
              if (data == "success") {
                document.getElementById(`direct-upload-progress-${id}`).classList.add("hidden")
                element.classList.add("direct-upload-instant-success")
              } else {
                element.classList.add("direct-upload--error")
                element.setAttribute("data-tippy-content","["+filepath+"] "+data);
                window.reloadTippies();
              }
          }
      });
      
    }
  })