import consumer from "./consumer"

function compliments_subscription(city_id) {
  return consumer.subscriptions.create({ channel: "ComplimentsChannel", city_id: city_id }, {
    connected() {
    },

    disconnected() {
      this.unsubscribe();
    },

    received(data) {
      $("#compliments-live-feed").prepend(data.content);
      $("#compliments-live-feed").animate({ scrollTop: 0 }, 1000);
      setTimeout(function() {$(".compliment-card").addClass("in")}, 1000);
    }
  });
}

export default compliments_subscription;