import { Controller } from "stimulus"

export default class extends Controller {

    insertVariable(e) {
        let theElement = document.activeElement; //gets the element that had focus
        let position = theElement.selectionStart; //gets the position of the cursor in the element that had focus. 
        let data = theElement.value; //gets the data of the element with focus.
        let var_to_insert = e.currentTarget.dataset.emailModalVariableValue //gets the name of the variable that triggered this event

        data = data.slice(0, position) +`{${var_to_insert}}` + data.slice(position); //inserts the variable
        theElement.value = data;

        theElement.focus();

        //this will also put the cursor at the end of the inserted variable
        let newPosition = position + var_to_insert.length + 2;
        theElement.selectionStart = newPosition;
        theElement.selectionEnd = newPosition;
        e.preventDefault();
    }
} 