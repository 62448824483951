import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static targets = ["button","radio"]

    initialize () {
        this.updateSelected = this.updateSelected.bind(this)
    }

    connect () {
        super.connect()
        for(var i = 0; i < this.radioTargets.length; i++) {
            this.radioTargets[i].addEventListener('change', this.updateSelected)
        }
    }

    disconnect () {
        for(var i = 0; i < this.radioTargets.length; i++) {
            this.radioTargets[i].removeEventListener('change', this.updateSelected)
        }
        // super.disconnect()   
    }

    beforeReflex (element, reflex, noop, reflexId) {
        document.body.classList.add('wait')
        super.beforeReflex(element, reflex, noop, reflexId);
    }
    
    afterReflex (element, reflex, noop, reflexId) {
        document.body.classList.remove('wait')
        this.reload_tippies();
        super.afterReflex(element, reflex, noop, reflexId);
    }

    updateSelected(event){
        for(var i = 0; i < this.radioTargets.length; i++) {
            if (this.radioTargets[i].checked) {
                this.buttonTargets[i].classList.add("button-selector-group-active")
                this.buttonTargets[i].classList.remove("button-selector-group-inactive")
            } else {
                this.buttonTargets[i].classList.remove("button-selector-group-active")
                this.buttonTargets[i].classList.add("button-selector-group-inactive")
            }
        }
    }

}
