import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static targets = ["item", "category", "searchField", "clearFilter", "resultCount", "sortableTable"];

    searchFilter(){

        let selectedCategories = this.categoryTargets.filter( category => category.checked ) || [] //filter for selected levels onlyasdf
        let categoryValues = selectedCategories.map( category => category.value )
        let searchValue = this.searchFieldTarget.value.toLowerCase() // Extracted this because we can not query the length of a regular expression.
        let searchRegex = new RegExp(`${searchValue.toLowerCase()}.*`) // the value in the search box as a regular expression
        let filterableItems = this.getFilterableItems();
        let resultCount = 0
        let hasFilterText = ""

        this.categoryTargets.forEach( category => {
            if (category.checked){
                category.parentNode.classList.add("small-filter-button-indigo-active") //parentNode because category is a hidden checkbox. Styling needs to be applied to its enclosing label.
                category.parentNode.classList.remove("small-filter-button-indigo-inactive")
            } else {
                category.parentNode.classList.add("small-filter-button-indigo-inactive")
                category.parentNode.classList.remove("small-filter-button-indigo-active")
            }
        })

        filterableItems.forEach( item => {
            if( (categoryValues.length === 0 || categoryValues.includes( item.dataset["filterableCategory"] ) ) && ( searchValue.length === 0 || searchRegex.test( item.dataset["filterableText"].toLowerCase() ) )){
                resultCount++;
                item.classList.remove("hidden")
            } else {
                item.classList.add("hidden")
            }
        })

        if(categoryValues.length === 0 && searchValue.length === 0){
            this.clearFilterTarget.classList.add("hidden")
        } else {
            hasFilterText = " (Filtered)"
            this.clearFilterTarget.classList.remove("hidden")
        }

        if (this.hasResultCountTarget) {
            this.resultCountTarget.textContent = `${resultCount}${hasFilterText}`
        }
    }

    clearFilters(){
        this.categoryTargets.forEach( category => {
            category.checked = ""
        })
        this.searchFieldTarget.value = ""
        this.searchFilter()

        if (this.hasResultCountTarget) {
            let filterableItems = this.getFilterableItems();
            this.resultCountTarget.textContent = filterableItems.length
        }
    }

    getFilterableItems(){
        return this.itemTargets.filter( item => !(Array.from(item.classList).includes("unfilterable")))
    }

    afterReflex(){
        return
    }

    finalizeReflex(anchorElement){ //finalize because we want this to run after the DOM manipulations have happened.
        const { reflex } = anchorElement.dataset
        if (reflex.match(/toggle_dancer$/) && Array.from(anchorElement.classList).includes("dancer_selected")){
            this.searchFilter()
        }
    }

    preventReturns(e) {
        if(e.keyCode == 13) {
            e.preventDefault();
            return false;
        }
    }

    sortTable(e) {
        let asc = e.currentTarget.dataset["descending"] != "true"
        Array.from(this.itemTargets)
            .sort(this.rowSortComparer(e.currentTarget.dataset["sortBy"], asc))
            .forEach(tr => this.sortableTableTarget.appendChild(tr) );
    };

    rowSortComparer(dataName, asc) { 
        // This is used by the array.sort() function...
        return function(a, b) { 
    
            // This is a transient function, that is called straight away. 
            // It allows passing in different order of args, based on 
            // the ascending/descending order.
            return function(v1, v2) {
    
                // sort based on a numeric or localeCompare, based on type...
                return (v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2)) 
                    ? v1 - v2 
                    : v1.toString().localeCompare(v2);
            }(asc ? a.dataset[dataName] : b.dataset[dataName], asc ? b.dataset[dataName] : a.dataset[dataName]);
        }
    };

  }
