import ApplicationController from './application_controller'

export default class extends ApplicationController {
    static targets = ['source', 'output', 'checkall']
      
    initialize () {
        this.process = this.process.bind(this)
        this.toggleAll = this.toggleAll.bind(this)
    }

    connect () {
        super.connect()

        for(var i = 0; i < this.sourceTargets.length; i++) {
            this.sourceTargets[i].addEventListener('change', this.process)
        }

        if (this.hasCheckallTarget) {
            this.checkallTarget.addEventListener('change', this.toggleAll)
        }
    }

    disconnect () {
        for(var i = 0; i < this.sourceTargets.length; i++) {
            this.sourceTargets[i].removeEventListener('change', this.process)
        }
    }
  
    process(event) {
        var total = 0

        for(var i = 0; i < this.sourceTargets.length; i++) {
            if (this.sourceTargets[i].checked) {
                total += 1
            }
        }

        this.outputTarget.innerText = total
    }

    toggleAll(event) {
        var newValue = event.target.checked;

        for(var i = 0; i < this.sourceTargets.length; i++) {
            this.sourceTargets[i].checked = newValue
        }

        this.process(event);
    }
        
  }